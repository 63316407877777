<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-atom"></i> Analisa Perbandingan Hasil
      </h6>
    </h1>
    
    <base-header class="pb-6">
    <b-row>
      <b-col xl="12">
        <b-card  style="height: 180px;">
          <h5>Cari Berdasarkan</h5>
          <div class="mb-4 row">

            <!-- Jenis Industri -->

            <!-- <div class="col-lg-2">
              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Jenis Industri</label>
                <div class="col-lg-12">
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType">
                      <el-option value="">Semua</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              </div> -->

             <!-- Provinsi -->
             <!-- <div class="col-lg-2">
               <div class="form-group row">
                  <label class="col-lg-12 col-form-label form-control-label">Provinsi</label>
                  <div class="col-lg-12">
                    <base-input name="Provinsi" rules="required">
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
             </div> -->

             <!-- Kabupaten kota -->
             <!-- <div class="col-lg-2">
               <div class="form-group row">
                 <label class="col-lg-12 col-form-label form-control-label">Kabupaten / Kota</label>
                 <div class="col-lg-12">
                   <base-input name="Kabupaten / Kota" rules="required">
                     <select
                       class="form-control"
                       v-model="form.umum.compCity"
                     >
                       <option
                         v-for="(option, idx) in provideSelect.kabupatenKota"
                         :key="idx"
                         :value="option.id"
                       >
                         {{ option.name }}
                       </option>
                     </select>
                   </base-input>
                 </div>
               </div>
             </div> -->


             <!-- Industri 1 -->
             <div class="col-lg-2">
              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Industri 1</label>
                <div class="col-lg-12">
                  <base-input class="m-0 mr-4">
                    <el-select filterable placeholder="Industri 1" v-model="userInput.companyName1" @change="getMyCompliencePoint1">
                      <el-option
                        v-for="option, idx in provideSelect.companyName"
                        :key="option.id"
                        :label="option.name "
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
             </div>
              

             <!-- Industri 2 -->
             <div class="col-lg-2">
              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Industri 2</label>
                <div class="col-lg-12">
                  <base-input class="m-0 mr-4">
                    <el-select filterable placeholder="Industri 2" v-model="userInput.companyName2"  @change="getMyCompliencePoint2">
                      <el-option
                        v-for="option, idx in provideSelect.companyName"
                        :key="option.id"
                        :label="option.name "
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
             </div>

            <!-- titik point 1 -->
            <div class="col-lg-2">
              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Titik Penaatan 1</label>
                <div class="col-lg-12">
                  <base-input class="m-0 mr-4">
                    <el-select filterable placeholder="Titik Penaatan 1" v-model="userInput.compliancePoint1">
                      <el-option
                        v-for="option, idx in listDataCompliencePoints1"
                        :key="option.id"
                        :label="option.spot_name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
             </div>

             <!-- titik point 2 -->
            <div class="col-lg-2">
              <div class="form-group row">
                <label class="col-lg-12 col-form-label form-control-label">Titik Penaatan 2</label>
                <div class="col-lg-12">
                  <base-input class="m-0 mr-4">
                    <el-select filterable placeholder="Titik Penaatan 2" v-model="userInput.compliancePoint2">
                      <el-option value="">Semua</el-option>
                      <el-option
                        v-for="option, idx in listDataCompliencePoints2"
                        :key="option.id"
                        :label="option.spot_name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
             </div>
              
              <div class="col-2">
                <div class="form-group row">
                  <label class="col-lg-12 col-form-label form-control-label">&nbsp;</label>
                  <div class="col-lg-12">
                    <base-button
                      class="w-100"
                      size="md"
                      type="primary"
                      @click="compareNow()"
                    >
                      Bandingkan
                    </base-button>
                  </div>
                </div>
              </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
            </b-row>
            </template>
            <ul class="list-group">
              <li class="list-group-item">
                <h2><b>pH</b></h2> 
                <VueApexCharts v-if="hasCompare"  height="350px" type="area" :options="options" :series="seriesPH"></VueApexCharts>
              </li>
              <li class="list-group-item">
                <h2><b>COD (mg/l)</b></h2>
                <VueApexCharts v-if="hasCompare"  height="350px" type="area" :options="options" :series="seriesCOD"></VueApexCharts>
              </li>
              <li class="list-group-item">
                <h2><b>TSS (mg/l)</b></h2>
                <VueApexCharts v-if="hasCompare"  height="350px" type="area" :options="options" :series="seriesTSS"></VueApexCharts>
              </li>
              <li class="list-group-item">
                <h2><b>NH3N (mg/l)</b></h2>
                <VueApexCharts v-if="hasCompare"  height="350px" type="area" :options="options" :series="seriesNH3N"></VueApexCharts>
              </li>
              <li class="list-group-item">
                <h2><b>Debit (m3/h)</b></h2>
                <VueApexCharts v-if="hasCompare"  height="350px" type="area" :options="options" :series="seriesDEBIT"></VueApexCharts>
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import API from '../../api/base_url'
import { Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import VueApexCharts from 'vue-apexcharts';
import Swal from "sweetalert2";
import moment from 'moment'



export default {
components: {
  VueApexCharts,
  BaseHeader,
  DataSparingChart,
  [Select.name]: Select,
  [Option.name]: Option,
},
mounted() {
  // this.getSelectFormValue()
  this.getCompanyName()
  // this.getCompanyType()
  // this.getCompliencePoint()

},
data() {
  return {
    listDataCompliencePoints1: [],
    listDataCompliencePoints2: [],
    form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: null,
          compProvince: null,
        },
      },
    seriesNames: ["Series 1", "Series 2"],
    hasCompare:false,
    provideSelect: {
      flatPickerConfig: {
        dateFormat: "U",
        allowInput: true,
        altInput: true,
        altFormat: "d-m-Y H:i",
        enableTime: true,
        time_24hr: true,
        defaultHour: 0,
        mode: "range"
      },
      registrationType:[],
      compiencePoint:[],
      companyType:[],
      companyName:[],
      provinsi:[],
      kabupatenKota:[],
      regency:[]
    },
    userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName1:"",
      companyName2:"",
      compliancePoint1:"",
      compliancePoint2:"",
      province:"",
      regency:"",
    },
    companyType: [],
    options: {
      colors:['#fb6340','#3d9973'],
      chart: {
        id: 'vuechart-example',
        toolbar:{
          show:false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        categories: ['10.20', '10.30', '10.40', '10.50', '11.00', '11.10', '11.20', '11.30']
      },
      
      tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
    },
    seriesPH: [
        {
          name: 'series - 1',
          data: [],
        },
        {
          name: 'series - 2',
          data: [],
        }
    ],
    seriesCOD: [
        {
          name: 'series - 1',
          data: [],
        },
        {
          name: 'series - 2',
          data: [],
        }
    ],
    seriesTSS: [
        {
          name: 'series - 1',
          data: [],
        },
        {
          name: 'series - 2',
          data: [],
        }
    ],
    seriesNH3N: [
        {
          name: 'series - 1',
          data: [],
        },
        {
          name: 'series - 2',
          data: [],
        }
    ],
    seriesDEBIT: [
        {
          name: 'series - 1',
          data: [],
        },
        {
          name: 'series - 2',
          data: [],
        }
    ],
  };
},
methods: {
  getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
  },
  getMyCompliencePoint1() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName1}` , {headers})
       .then(({data:content}) => {
         this.listDataCompliencePoints1 = content.data
         this.userInput.compliancePoint1 = ""
       })
       .catch((err) => {
         console.log(err);
       })
  },
  getMyCompliencePoint2() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName2}` , {headers})
       .then(({data:content}) => {
         this.listDataCompliencePoints2 = content.data
         this.userInput.compliancePoint2 = ""
       })
       .catch((err) => {
         console.log(err);
       })
  },
  compareNow() {
   

   if (!this.userInput.companyName1 || !this.userInput.companyName2) {
     Swal.fire('Anda wajib memilih kedua nama industri ', '', 'error')
   } else {


   // new
   let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
   }

   let company_id_one = this.userInput.companyName1
   let company_id_two = this.userInput.companyName2
   let compliance_point_id_one = this.userInput.compliancePoint1
   let compliance_point_id_two = this.userInput.compliancePoint2
   


   API.get(`analisa-perbandingan?station_ids=${compliance_point_id_one},${compliance_point_id_two}`, {headers})
     .then(({data:content}) => {
       console.log(content.data);

       let ph1 = []
       let ph2 = []
       let cod1 = []
       let cod2 = []
       let tss1 = []
       let tss2 = []
       let nh3n1 = []
       let nh3n2 = []
       let debit1 = []
       let debit2 = []
       let time = []
       let phFirstName = ""
       let phSecondName = ""
       let codFirstName = ""
       let codSecondName = ""
       let tssFirstName = ""
       let tssSecondName = ""
       let nh3nFirstName = ""
       let nh3nSecondName = ""
       let debitFirstName = ""
       let debitSecondName = ""

      //  PH
        for (let j = 0; j < content.data[0].lines[0].data.length; j++) {
          ph1.push(content.data[0].lines[0].data[j].toFixed(2))
          ph2.push(content.data[0].lines[1].data[j].toFixed(2))
        }
        phFirstName = content.data[0].lines[0].comp_name
        phSecondName = content.data[0].lines[1].comp_name
       

      //  COD
        for (let j = 0; j < content.data[1].lines[0].data.length; j++) {
          cod1.push(content.data[1].lines[0].data[j].toFixed(2))
          cod2.push(content.data[1].lines[1].data[j].toFixed(2))
        }
        codFirstName = content.data[1].lines[0].comp_name
        codSecondName = content.data[1].lines[1].comp_name

      //  TSS
        for (let j = 0; j < content.data[2].lines[0].data.length; j++) {
          tss1.push(content.data[2].lines[0].data[j].toFixed(2))
          tss2.push(content.data[2].lines[1].data[j].toFixed(2))
        }
        tssFirstName = content.data[2].lines[0].comp_name
        tssSecondName = content.data[2].lines[1].comp_name

      //  NH3N
        for (let j = 0; j < content.data[3].lines[0].data.length; j++) {
          nh3n1.push(content.data[3].lines[0].data[j].toFixed(2))
          nh3n2.push(content.data[3].lines[1].data[j].toFixed(2))
        }
        nh3nFirstName = content.data[3].lines[0].comp_name
        nh3nSecondName = content.data[3].lines[1].comp_name

      //  DEBIT
        for (let j = 0; j < content.data[4].lines[0].data.length; j++) {
          debit1.push(content.data[4].lines[0].data[j].toFixed(2))
          debit2.push(content.data[4].lines[1].data[j].toFixed(2))
        }
        debitFirstName = content.data[4].lines[0].comp_name
        debitSecondName = content.data[4].lines[1].comp_name

      //  TIME
        for (let j = 0; j < content.data[4].timestamps.length; j++) {
         time.push(this.formatDate(content.data[4].timestamps[j]))
        }


     


        this.seriesPH = [
             {
               name: phFirstName,
               data: ph1,
             },
             {
               name: phSecondName,
               data: ph2
             }
         ],

        this.seriesCOD = [
             {
               name: codFirstName,
               data: cod1,
             },
             {
               name: codSecondName,
               data: cod2
             }
         ],

        this.seriesTSS = [
             {
               name: tssFirstName,
               data: tss1,
             },
             {
               name: tssSecondName,
               data: tss2
             }
         ],

        this.seriesNH3N = [
             {
               name: nh3nFirstName,
               data: nh3n1,
             },
             {
               name: nh3nSecondName,
               data: nh3n2
             }
         ],

        this.seriesDEBIT = [
             {
               name: debitFirstName,
               data: debit1,
             },
             {
               name: debitSecondName,
               data: debit2
             }
         ],

         this.options.xaxis.categories = time

        Swal.fire('Menampilkan hasil perbandingan', '', 'success')
         this.hasCompare = true
     })
     .catch((err) => {
       console.log(err);
       Swal.fire('Data tidak ditemukan', '', 'error')
     })
   }

  
 },
  // new
  getSelectFormValue() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCityDepentOnProvince(data) {
    this.provideSelect.kabupatenKota = [];
    API.get(`region/provinces/regencies?province_id=${data}`)
    // API.get(`region/regencies/province/${data}`)
      .then(({ data: content }) => {
        this.provideSelect.kabupatenKota = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  changeSeriesName(index, newName) {
    this.series[index].name = newName;
  },
  formatDate(timestamp){
    const momentObj = moment.unix(timestamp);
    const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
    return humanDate;
  },

 
  getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('companies/types?page=1&page_size=100', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
  },
},
};
</script>


  